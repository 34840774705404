import React, {useState} from 'react';
import s from './HeaderDropdown.module.css'
import arrow_dropdown from '../../assets/images/publicPages/arrow_dropdown.svg'
import {ReactComponent as CardCoin} from '../../assets/images/publicPages/card-coin.svg'
import {ReactComponent as Bitcoin} from '../../assets/images/publicPages/bitcoin-refresh.svg'
import {ReactComponent as WalletMoney} from '../../assets/images/publicPages/wallet-money.svg'
import {ReactComponent as Transfer} from '../../assets/images/publicPages/transfer.svg'
import {ReactComponent as Medal} from '../../assets/images/publicPages/medal-star.svg'
// import {ReactComponent as WhiteLabel} from '../../assets/images/publicPages/whitelabel.svg'
// import {ReactComponent as Iban} from '../../assets/images/publicPages/iban.svg'
import {Link} from "react-router-dom";
import Icon from "@ant-design/icons";
import {
    // DEDICATED_IBANS,
    FOREIGN_EXCHANGE,
    MONEY_ORDERS,
    MONEY_TRANSFERS,
    PAYMENT_SERVICE_PROVIDER,
    VIRTUAL_CURRENCIES,
    // WHITE_LABEL_SOLUTIONS,
} from "../../constants/RoutesConstants";

const HeaderDropdown = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    return (
        <div className={s.dropdown_wrapper}
             onMouseEnter={() => setIsDropdownOpen(true)}
        >
            <div className={s.title}>
                Services <img src={arrow_dropdown} alt=""/>
            </div>
            <div className={isDropdownOpen ? s.dropdown_content : s.hidden} onMouseLeave={() => setIsDropdownOpen(false)}>
               <Link to={VIRTUAL_CURRENCIES} className={s.dropdown_link}>
                    <Icon component={() => <Bitcoin/>} rev={undefined}/>
                    <p className={s.link_text}>Embracing the World of Virtual Currencies</p>
                </Link>

                <Link to={FOREIGN_EXCHANGE} className={s.dropdown_link}>
                    <Icon component={() => <CardCoin/>} rev={undefined}/>
                    <p className={s.link_text}>Foreign Exchange Excellence</p>
                </Link>

                <Link to={MONEY_ORDERS} className={s.dropdown_link}>
                    <Icon component={() => <WalletMoney/>} rev={undefined}/>
                    <p className={s.link_text}>Effortless Money Orders</p>
                </Link>

                <Link to={MONEY_TRANSFERS} className={s.dropdown_link}>
                    <Icon component={() => <Transfer/>} rev={undefined}/>
                    <p className={s.link_text}>Seamless Money Transfers</p>
                </Link>

                <Link to={PAYMENT_SERVICE_PROVIDER} className={s.dropdown_link}>
                    <Icon component={() => <Medal/>} rev={undefined}/>
                    <p className={s.link_text}>Your Trusted Payment Service Provider</p>
                </Link>

                {/* <Link to={WHITE_LABEL_SOLUTIONS} className={s.dropdown_link}>
                    <Icon component={() => <WhiteLabel/>} rev={undefined}/>
                    <p className={s.link_text}>White Label Solutions</p>
                </Link> */}

                {/* <Link to={DEDICATED_IBANS} className={s.dropdown_link}>
                    <Icon component={() => <Iban/>} rev={undefined}/>
                    <p className={s.link_text}>Dedicated IBANs</p>
                </Link> */}
            </div>
        </div>
    );
};

export default HeaderDropdown;
